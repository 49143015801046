<template>
    <div class="full-page">
        <div class="content">
            <div class="container">
            <loading :active="loginStatus == 'PENDING'" loader="dots" :is-full-page="true" color="#68ACE1"></loading>
                <div class="col-md-8 ml-30 col-sm-12">
                    <div class="row ">
                        <div class="col-4 bg-dark-o rounded-left no-padding">
                            <div>
                                <img src="@/assets/images/logo_bajo.png" class="logo" >
                            </div>
                            <div class="text-center content-rigths">
                                <span class="rights text-white w-100 font-12 ml-2">
                                    @ CleverBusiness Solutions, SL <br> All rights reserved
                                </span>
                            </div>
                        </div>
                        <div class="col-8 bg-light">
                            <div class="card bg-light card-login border-0">
                                <div v-if="!reset_password">
                                    <div class="card-header mt-4 d-flex justify-content-center bg-light">
                                        <img src="@/assets/images/logo_cleverdocs.png" width="250px" alt="">
                                    </div>
                                    <div class="card-body ">
                                        <div class="card-body">
                                        <form v-on:submit.prevent="onPressLogin">
                                                <div class="form-group">
                                                    <label>{{$t("Usuario")}}</label>
                                                    <input type="email" v-model="login.email" autocomplete="false" :placeholder="$t('Usuario')" class="form-control">
                                                    <div v-if="$v.login.email.$error" class="invalid-feedback">
                                                        <div  v-if="!$v.login.email.required" >{{$t('Campo_requerido')}}</div>
                                                        <div  v-if="!$v.login.email.emal" >{{$t('validation_email')}}</div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>{{$t("Password")}}</label>
                                                    <input type="password" v-model="login.password" autocomplete="false" :placeholder="$t('Password')" class="form-control">
                                                    <div v-if="$v.login.password.$error" class="invalid-feedback">
                                                        <span v-if="!$v.login.password.required" >{{$t('Campo_requerido')}}</span>
                                                    </div>
                                                </div>
                                                <div v-if="loginStatus == 'ERROR'" class="invalid-feedback">
                                                    <span >{{$t('error_login')}}</span>
                                                </div>
                                        </form>
                                        </div>
                                    
                                        <!-- <div class="form-group">
                                            <route-link class="form-check-label" to="/recovery">{{$t('recoveryPassword')}}</route-link>
                                        </div> -->
                                    </div>
                                    <div class="card-footer ml-auto mr-auto mb-5 col-12">
                                        <div class="w-100">
                                            <button type="submit" @click="onPressLogin()" class="col-12 btn bg-primary btn-info text-white font-weight-bold">{{$t("Login")}}</button>
                                        </div>
                                        <div class="text-center w-100 mt-2" @click="reset_password=true" >
                                            <span>
                                                {{$t("recoveryPassword")}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="card-header mt-4 d-flex justify-content-center bg-light">
                                        <img src="@/assets/images/logo_cleverdocs.png" width="250px" alt="">
                                    </div>
                                    <div class="card-body ">
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label class="text-justify w-100">{{$t("recoveryText")}} </label>
                                            </div>
                                            <div class="form-group">
                                                <label>{{$t("Email")}}</label>
                                                <input type="email" v-model="recovery.email" autocomplete="false" :placeholder="$t('Email')" class="form-control">
                                                <div v-if="$v.recovery.email.$error" class="invalid-feedback">
                                                    <div  v-if="!$v.recovery.email.required" >{{$t('Campo_requerido')}}</div>
                                                    <div  v-if="!$v.recovery.email.emal" >{{$t('validation_email')}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer ml-auto mr-auto mb-5 col-12">
                                        <div class="w-100">
                                            <button type="submit" @click="onResetPassword()" class="col-12 btn bg-primary btn-info text-white font-weight-bold">{{$t("Enviar")}}</button>
                                        </div>
                                        <div class="text-center w-100 mt-2" @click="reset_password=false" >
                                            <span>
                                                {{$t("Volver")}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { required, email} from 'vuelidate/lib/validators';
    import { mapGetters } from 'vuex';
    import Loading from 'vue-loading-overlay';

    export default {
        data() {
            return {
                login : {
                    email   : null,
                    password: null,
                },
                error: null,
                recovery : {
                                        email   : null,

                },
                reset_password : false,
            }
        },
        validations(){
            return {
                login : {
                    email : {
                        required,
                        email
                    },
                    password : {
                        required
                    }, 
                },
                recovery : {
                      email : {
                        required,
                        email
                    },
                }           
            }
        },
        computed: {
            ...mapGetters({
                loginStatus: 'login/loginStatus',
            })
        },
        components : {
            Loading
        },
        methods : {
            onPressLogin(){
                this.$v.login.$touch();
                if(!this.$v.login.$invalid){
                     this.$store.dispatch("login/login", {
                        email: this.login.email, 
                        password: this.login.password
                    })
                }
            },
            onResetPassword(){
                this.$v.recovery.$touch();
                let self = this;
                if(!this.$v.recovery.$invalid){
                    this.$store.dispatch('isLoadingOn');
                    this.$axios.post("auth/recovery_password",{ email : this.recovery.email})
                    .then(() =>{
                        self.reset_password = false;
                        self.recovery.email = null;
                        this.$store.dispatch('isLoadingOff');
                        self.$dialog.alert({
                            title: self.$t("RecoveryPassword"),
                            body: self.$t("RecoveryPasswordText")
                        });
                        self.$v.recovery.$reset();
                    })
                    .catch(() => {
                        this.$store.dispatch('isLoadingOff');
                        self.$dialog.alert({
                            title: self.$t("RecoveryPassword"),
                            body: self.$t("server_error")
                        })
                    })
                }
            }
        },
    }
</script>
<style>
.full-page>.content:first-of-type {
    padding-top: 20vh;
}
.full-page {
    /* :before */
    background: url(/login.jpg);
    background-size: 44% 100%;
    background-repeat: no-repeat;
    background-position: 11% top;
}
.card-login::before{
    opacity :0.1 !important;
}
.full-page {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}
.invalid-feedback{
  display: block;
}
.font-12{
    font-size: 12px;
}
.content-rigths{
    bottom: 15px;
    position: absolute;
}
.ml-30{
    margin-left: 28%;
}
.bg-dark-o{
    background: #343a40e0;
}
.bg-gray{
    background-color: #f0f0f0;
}
.logo{
    width: 200px;
}
@media (max-width: 991.98px) { 
      .full-page {
        background: url(/login.jpg);
        background-size: 40% 100%;
        background-repeat: no-repeat;
        height: auto;
    }
    .ml-30 {
        margin-left: 0;
    }
    .logo{
        width: 100%;
    }
    .ml-30 {
        margin-left: 16.6%;
    }
    .full-page>.content:first-of-type {
        padding-top: 10vh;
        padding-bottom: 5vh;
    }
 }

@media (max-width: 576px) { 
    .ml-30{
        margin-left: initial;
    }
    .full-page {
        background: url('/login.jpg');
        background-size: 600px 100%;
        background-repeat: no-repeat;
        height: 100%;
    }
    .logo{
        width: 100%;
    }
    .rights.ml-2{           
        margin-left: 0px !important;         
    }
    .full-page>.content:first-of-type{
        padding-top : 15vh;
    }
    .card .card-body {
        padding: 0;
    }
    .card .card-footer {
        padding: 0;
    }
    .no-padding{
        padding: 0;
    }
 }
</style>